<template>
  <v-footer class="py-2 mt-9" dark color="rgba(0, 0, 0, 0.7)" height="auto">
    <v-container class="mx-auto">
      <v-row>
        <v-col cols="9">
          <base-btn
            v-for="(item, i) in items"
            :key="i"
            :href="item.href"
            class="ml-0 mr-3"
            square
            target="_blank"
          >
            <v-icon v-text="item.icon" />
          </base-btn>
        </v-col>

        <v-spacer />

        <v-btn
          class="mr-0"
          color="white"
          light
          fab
          small
          fixed
          bottom
          right
          title="Go to top"
          @click="$vuetify.goTo(0)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "CoreFooter",

  data: () => ({
    items: [
      {
        href: "https://twitter.com/gomesunnhgomesu",
        icon: "mdi-twitter",
      },
      /*
      {
        href: "#!",
        icon: "mdi-instagram",
      },
      */
      {
        href: "https://www.facebook.com/gomesunonahagomesu",
        icon: "mdi-facebook",
      },
    ],
  }),
};
</script>
